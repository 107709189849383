import React from "react";

function Edu(props) {
  return (
    <div className="container-edu">
      <div className="cn-0">
        <div className="cnn-0">WORK EXPERIENCE</div>
        <div className="bor-left">
          <div className="inner-txt">
            IT Support Engineer <br></br>Algorythma <br></br>March 2019 -
            Present
          </div>
          <span className="dot"></span>
          <div>
            <ul className="ul-1">
              <li>My duties as a IT Support Engineer are as follow:</li>
              <ul>
                <li>
                  Taking ownership of employee/client issues reported and seeing
                  problems through to resolution
                </li>
                <li>
                  Researching, diagnosing, troubleshooting and identifying
                  solutions to resolve system issues
                </li>
                <li>
                  Following standard procedures for proper escalation of
                  unresolved issues to the appropriate internal teams
                </li>
                <li>
                  Research and identify solutions to software and hardware
                  issues
                </li>
                <li>
                  Ask employee/client targeted questions to quickly understand
                  the root of the problem
                </li>
                <li>
                  Talk employee/client through a series of actions, either via
                  phone, email or chat, until they’ve solved a technical issue
                </li>
                <li>
                  Refer to internal database or external resources to provide
                  accurate tech solutions
                </li>
                <li>Ensure all issues are properly logged</li>
              </ul>
            </ul>
          </div>
          <div className="pos">
            <div className="inner-txt-1">
              IT Support Specialist <br></br>SAAL Operating Systems<br></br>Jan
              2019 - March 2019
            </div>
            <span className="dot-1"></span>
            <div>
              <ul className="ul-1">
                <li>My duties as a IT Support Specialist are as follow:</li>
                <ul>
                  <li>Checking the status of all systems and hardware.</li>
                  <li>
                    Responding to help requests from staff members or clients.
                  </li>
                  <li>Installing and configuring new systems and hardware.</li>
                  <li>Running reports on systems and hardware status.</li>
                  <li>Replacing malfunctioning or damaged hardware.</li>
                  <li>Troubleshooting software</li>
                  <li>
                    Testing, assessing, and learning about updates and new
                    technology.
                  </li>
                </ul>
              </ul>
            </div>
          </div>

          <div className="pos-1">
            <div className="inner-txt-2">
              Customer Service Representative <br></br>Abu Dhabi Distribution{" "}
              <br></br>August 2016 - November 2018
            </div>
            <span className="dot-2"></span>
            <div>
              <ul className="ul-1">
                <li>Mentoring and Assisting the supervisor on the floor.</li>
                <ul>
                  <li>Training of new agents.</li>
                  <li>
                    Making daily reports and submitted to the supervisors and to
                    the management.
                  </li>
                  <li>
                    Handle customers complains and inquiries through Email.
                  </li>
                  <li>Handle customers direct chat inquiries.</li>
                  <li>Listen and respond to customers’ needs and concerns.</li>
                  <li>Provide information about services.</li>
                  <li>
                    Apply requests and assist customers with their billing and
                    payments.
                  </li>
                  <li>Review or make changes to customer's accounts.</li>
                  <li>Handle complaints.</li>
                  <li>
                    Record details of customer contacts and actions taken.
                  </li>
                  <li>Research answers or solutions as needed.</li>
                  <li>
                    Refer customers to supervisors managers, or others who can
                    help.
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="cn-1">
        <div className="cnn-0">SKILLS &amp; TOOLS </div>
        <div className="skills">Frontend</div>
        <div>HTML5</div>
        <div class="progress1 progress-moved"><div class="progress-bar1"></div></div> 
        <div>CSS3</div>
        <div class="progress2 progress-moved"><div class="progress-bar2"></div></div> 
        <div>React.js</div>
        <div class="progress3 progress-moved"><div class="progress-bar3"></div></div> 
        <div className="skills">Backend</div>
        <div>Javascript</div>
        <div class="progress3 progress-moved"><div class="progress-bar3"></div></div> 
        <div className="skills">IT Support</div>
        <div>Microsoft Office 365 Admin</div>
        <div class="progress4 progress-moved"><div class="progress-bar4"></div></div> 
        <div>G-Suite Admin</div>
        <div class="progress5 progress-moved"><div class="progress-bar5"></div></div> 
        <div>Active Directory</div>
        <div class="progress6 progress-moved"><div class="progress-bar6"></div></div> 
        <div>Windows &amp; Mac OS Troubleshooting</div>
        <div class="progress8 progress-moved"><div class="progress-bar8"></div></div>
        <div>Manage Engine Service Desk</div>
        <div class="progress7 progress-moved"><div class="progress-bar7"></div></div> 
        <div>Manage Engine Desktop Central</div>
        <div class="progress7 progress-moved"><div class="progress-bar7"></div></div> 
        <div>Manage Engine MDM</div>
        <div class="progress7 progress-moved"><div class="progress-bar7"></div></div> 
        <div>IT Onboarding</div>
        <div class="progress7 progress-moved"><div class="progress-bar7"></div></div> 
        <div>IT Procurment</div>
        <div class="progress8 progress-moved"><div class="progress-bar8"></div></div> 
      </div>
    </div>
  );
}

export default Edu;
