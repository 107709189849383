import React from "react";
import ph from '../ph.png';
import em from '../em.png';

function Socail(props) {
  return (
    <div className="outter-sm">
      <div className="sm-ic">
            <div className="sm-ic-link">
              <img src={ph} className="img-sm-p"/>
            </div>
            <div className="center-0">
            +971 56 286 5434
            </div>
        <div className="sm-ic-link">
          <a
            href="https://www.linkedin.com/in/ahmed-khairaldin"
            target="_blank"
          >
            <img src={props.linkedin} className="img-sm" />
          </a>
        </div>
        <div className="center-0"><a href="https://www.linkedin.com/in/ahmed-khairaldin" target="_blank">linkedin.com/in/ahmed-khairaldin
          </a></div>

        <div className="sm-ic-link"> 
        <a className="a-a" href="mailto:ahmed.khairaldin@icloud.com?Subject=Hello%20Ahmed%20I%20have%20a%20job%20for%20you" target="_top"><img src={em} className="img-sm-e"/></a>
          </div>
          <div className="center-0"><a href="mailto:ahmed.khairaldin@icloud.com?Subject=Hello%20Ahmed%20I%20have%20a%20job%20for%20you" target="_top">ahmed.khairaldin@icloud.com</a></div>
      
        <div className="sm-ic-git">
          <a href="https://github.com/ahmed-khairo" target="_blank">
            <img src={props.github} className="img-sm" />
          </a>
        </div>
        <div className="center-0"><a href="https://github.com/ahmed-khairo" target="_blank">github.com/ahmed-khairo</a></div>
      </div>
    </div>
  );
}

export default Socail;
