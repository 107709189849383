import React from "react";
import Socail from './social-media'
import git from '../git.png';
import apple from '../apple.png';
import whats from '../whats.png';
import linked from '../linked.png';
import ph from '../ph.png';
import em from '../em.png';

function Me(props) {
  return (
    <div>
      <div className="outer-div">
        <div className="img-loc">
          <img src={props.user} className="user-img" />
        </div>
        <div className="text">
          <div>
            <h4>Ahmed Khairaldin</h4>
            <h6>Front-end Developer / IT Support Engineer</h6>
          </div>
          <div>
          <Socail github={git} whatsapp={whats} linkedin={linked}/>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Me;

