import React from 'react';
import './App.css';
import Navbar from './components/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import menu from './menu.png';
import settings from './settings.png';
import ahmed from './ahmed.JPG';
import Me from './components/me';
import Socail from './components/social-media';
import git from './git.png';
import apple from './apple.png';
import whats from './whats.png';
import linked from './linked.jpg';
import Work from './components/work';
import Edu from './components/edu';
import SideMenu from 'react-sidemenu';
import './side-menu.css';
import { useState } from 'react';
import Firebase from './components/firebase';
import Job from './components/login';




// const items = [
//   {divider: true, label: 'Main navigation', value: 'main-nav'},
//   {label: 'item 1', value: 'item1', icon: 'fa-search',
//   children: [
//     {label: 'item 1.1', value: 'item1.1', icon: 'fa-snapchat',
//     children: [
//       {label: 'item 1.1.1', value: 'item1.1.1', icon: 'fa-anchor'},
//       {label: 'item 1.1.2', value: 'item1.1.2', icon: 'fa-bar-chart'}]},
//     {label: 'item 1.2', value: 'item1.2'}]},
//   {label: 'item 2', value: 'item2', icon: 'fa-automobile',
//   children: [
//     {label: 'item 2.1', value: 'item2.1',
//     children: [
//       {label: 'item 2.1.1', value: 'item2.1.1'},
//       {label: 'item 2.1.2', value: 'item2.1.2'}]},
//     {label: 'item 2.2', value: 'item2.2'}]},
//   {divider: true, label: 'Motors', value: 'motors-nav'},
//   {label: 'item 3', value: 'item3', icon: 'fa-beer'}
// ];


function App() {
  
  return (
    <div className="App">
      <div className="center-1">

      {/* <SideMenu items={items}/> */}
      {/* <Navbar menu={menu} settings={settings}/> */}
      <Me user={ahmed}/>      
      {/* <Edu /> */}
      <Work />
      <Job />
     <Edu />  
     
      </div>
      
    </div>
  );
}

export default App;

