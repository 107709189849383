import React from 'react';


function Job() {

  return (
    <div className="blink">
Open to new opportunities !!!
    </div>
  );
}

export default Job;