import React from "react";

function Work() {
  return (
    <div className="container-work">
      <div className="bor">CAREER SUMMARY</div>
      <div className="cn-0">
        Experienced Information Technology Support Engineer with a demonstrated
        history of working in the computer software industry. Strong information
        technology professional with a Bachelor of Engineering focused in
        Electrical and Electronics Engineering from Abu Dhabi University. I am
        confident that my skills and my passion for technology can be utilized
        in the work place. I like working on challenging projects and I’m
        looking for a suitable job where my skills can be utilized.
      </div>
    </div>
  );
}

export default Work;
