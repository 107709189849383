import React from 'react';



function Navbar(props) {

  return (
    <div className="navbar">
      <span><img src={props.menu} className="menu-size"/></span>
      <span>About Me</span>
      <span><img src={props.settings} className="settings-size"/></span>
    </div>
  );
}

export default Navbar;